import React, { useState } from "react"
import styled from "styled-components"

import TextRainbow from "../../../components/TextRainbow"

const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const TimeSelectorContainer = styled.div`
    width: fit-content;
    margin: auto auto;
    font-size: 150%;
    font-weight: bolder;
`
const SelectTime = styled.select`
    padding: 0.5em;
    background: transparent;
    border: 0;
    color: #FFF;
    font-weight: bolder;
    font-size: 150%;
`
const SelectTimeOption = styled.option`

`
const Button = styled.div`
    border-radius: 5px;
    padding: 0.5em;
    margin: 2em auto;
    background: #FFFFCF;
    color: #212121;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
`
const ToggleButton = styled.div<{isEnabled: boolean}>`
    display: inline-block;
    color: ${props => props.isEnabled ? '#C1E1C1' : '#FF6961'};
    font-weight: bolder;

    &:hover {
        cursor: pointer;
    }
`
const Error = styled.div`
    padding: 0.25em;
    width: 100%;
    background: #FF6961;
    color: #FFF;
    font-weight: bolder;
    margin: 1em;
`

const ValidateTimekeeper = (props: any) => {
    const { web3 } = props
    const ENDPOINT = process.env.NODE_ENV !== 'production' ? 'http://localhost:8888/' : 'https://api.harrydenley.com/'
    const date = new Date;
    const currentHour = date.getUTCHours() < 10 ? [0,date.getUTCHours()].join("") : date.getUTCHours();
    const currentHourOrig = date.getUTCHours();

    const [error, setError] = useState<string>("");
    const [hours, setHours] = useState<number>(currentHour as number);
    const [mins, setMins] = useState<number>(0);
    const [blFilterOnCurrentHour, setBlFilterOnCurrentHour] = useState<boolean>(true);

    const handleClick = async () => {
        const ensName = [hours, mins == 0 ? "00" : mins].join("h") +".eth";

        // Validate the activeAddress owns it!
        await fetch(`${ENDPOINT}/ethereum/ens-time-keepers/owner/eth/${ensName.replace(".eth", "")}`)
            .then(response => response.json())
            .then(res => {
                if(res.data.owner.toLowerCase() === props.web3.activeAddress.toLowerCase()) {
                    props.callback({
                        nextStep: 2,
                        ensLabel: ensName,
                        web3: props.web3
                    })
                    return
                }

                setError(`You do not own this ENS name. It belongs to ${res.data.owner.toLowerCase()}!`)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (<>
        <SubTitle>
            Which #TimeKeeper ENS name would you like to use? <br /><br />
            Connected Address: <TextRainbow text={web3 && web3.hasOwnProperty("activeAddress") ? web3.activeAddress : ""} /><br /><br />
            (I haven't implemented an off-chain index consumer to fetch the #TimeKeepers ENS names that you own. Please input it below)
        </SubTitle>

        {error != "" && <Error>{error}</Error>}

        Only show current hour: <ToggleButton 
                                    isEnabled={blFilterOnCurrentHour} 
                                    onClick={() => setBlFilterOnCurrentHour(!blFilterOnCurrentHour)}
                                >{blFilterOnCurrentHour ? 'Yes' : 'No'}</ToggleButton>

        <TimeSelectorContainer>
            <SelectTime name="hours" onChange={(ev) => setHours(ev.target.value as any)}>
                    {
                        Array.from(Array(24).keys()).map((hour) => {
                            if( (blFilterOnCurrentHour && hour !== currentHourOrig) ) {
                                return;
                            }

                            const formattedHour = String(hour).length < 2 ? [0,hour].join("") : hour;
                            return <SelectTimeOption 
                                        value={formattedHour}
                                    >{formattedHour}</SelectTimeOption>
                        })
                    }
            </SelectTime>
            H
            <SelectTime name="mins" onChange={(ev) => setMins(ev.target.value as any)}>
                    {
                        Array.from(Array(60).keys()).map((min) => {
                            const formattedMinute = String(min).length < 2 ? [0,min].join("") : min;
                            return <SelectTimeOption 
                                        value={formattedMinute}
                                    >{formattedMinute}</SelectTimeOption>
                        })
                    }
            </SelectTime>
            .eth

            <Button onClick={() => handleClick()}>
                Next!
            </Button>
        </TimeSelectorContainer>

    </>)
}

export default ValidateTimekeeper